// Function to calculate the monthly fee
function calculateMonthlyFee(kaution) {
    var fee = (kaution * 0.0399) / 12; // Calculate 5.5% of kaution divided by 12
    return fee.toFixed(2); // Round to 2 decimal places
}

// Function to update the HTML with calculated monthly fee
function updateMonthlyFee() {
    var kautionElements = document.querySelectorAll(".variable-replace[data-variable='kaution']");
    var phFeePlaceholder = "PH_FEE";

    if (kautionElements.length > 0) {
        kautionElements.forEach(function (kautionElement) {
            var kautionText = kautionElement.textContent;
            var kaution = parseFloat(kautionText);

            if (!isNaN(kaution)) {
                var monthlyFee = calculateMonthlyFee(kaution);
                var h3Elements = document.querySelectorAll("h3");

                if (h3Elements.length > 0) {
                    h3Elements.forEach(function (h3) {
                        var content = h3.innerHTML;

                        // Check if the PH_FEE placeholder exists, if not, replace elements with "monthly-fee" class
                        if (content.indexOf(phFeePlaceholder) !== -1) {
                            content = content.replace(phFeePlaceholder, '<span class="monthly-fee">' + monthlyFee + " €</span>");
                        } else {
                            // Replace elements with "monthly-fee" class
                            content = content.replace(/<span class="monthly-fee">(.*?)<\/span>/g, '<span class="monthly-fee">' + monthlyFee + " €</span>");
                        }

                        h3.innerHTML = content;
                        console.log("fee updated.");
                    });
                } else {
                    console.log("No h3 elements found.");
                }
            } else {
                // Handle invalid kaution value for this specific kautionElement
            }
        });
    } else {
        //console.log("No kaution elements found.");
    }
}


setInterval(updateMonthlyFee, 100);
// Check for the presence of elements every second and update if found
//setInterval(updateMonthlyFee, 500);

window.addEventListener('heyflow-change', (event) => {
    updateMonthlyFee();
    // Function to calculate the SHA-256 hash of a string
    async function sha256(str) {
        const encoder = new TextEncoder();
        const data = encoder.encode(str);

        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }

    // Get the input elements with data-variable="phone" and data-variable="phone1"
    const phoneInput = document.querySelector('input[data-variable="email-mieter"]');
    const phone1Input = document.querySelector('input[data-variable="email-mieter1"]');
    const hashIdInput = document.querySelector('input[data-variable="faOrderID"]');

    // Get the values entered by the user
    const phoneValue = phoneInput.value.trim();
    const phone1Value = phone1Input ? phone1Input.value.trim(): ""; // Check if phone1Input exists
    console.log("start 1:", phoneValue, "start 2:", phone1Value);

    // Choose the input field with real customer data
    let selectedValue = "";

    if (phoneValue && phoneValue !== "") {
        selectedValue = phoneValue;
        console.log("value 1:", selectedValue);
    } else if (phone1Value && phone1Value !== "") {
        selectedValue = phone1Value;
        console.log("value 2:", selectedValue);
    }

    // Trim leading and trailing whitespace
    selectedValue = selectedValue.trim();

    // Trim inner whitespace (multiple consecutive spaces) with a regular expression
    selectedValue = selectedValue.replace(/\s+/g, ' ');

    // Calculate the SHA-256 hash of the trimmed selectedValue
    sha256(selectedValue)
    .then(hash => {
        console.log("SHA-256 Hash of Selected Value:", hash);

        // Set the hash value in the input field with data-variable="faOrderID"
        hashIdInput.value = hash;
    })
    .catch(error => {
        console.error("Error calculating SHA-256 hash:", error);
    });


});